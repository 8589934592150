// ** Icons Import
import { Clipboard, Mail, MessageSquare, CheckSquare, Calendar, BookOpen, Download, FileText, Circle, ShoppingCart, User, Shield, Package, Settings, Truck, MapPin, Users, Folder, Briefcase, Clock, Cpu, CreditCard, Layout, UserPlus, Crosshair, Layers, Slack, Trello, Archive, Edit3, List, Printer, Anchor, FastForward } from 'react-feather'

export default [
  {
    header: 'Workspace'
  },
  {
    id: 'users',
    title: 'Human Resources',
    icon: <User size={20} />,
    permissions: ['hr', 'masteradmin'],
    children: [
      {
        id: 'list',
        title: 'Employees',
        icon: <Users size={12} />,
        navLink: '/apps/user/list',
        permissions: ['hr', 'masteradmin']
      },
      {
        id: 'department',
        title: 'Departments',
        icon: <Folder size={12} />,
        navLink: '/apps/user/department',
        permissions: ['hr', 'masteradmin']
      },
      {
        id: 'skill',
        title: 'Skills & Certifications',
        icon: <Cpu size={12} />,
        navLink: '/apps/hrm/skills/list',
        permissions: ['hr', 'masteradmin']
      },
      {
        id: 'timesheet',
        title: 'Timesheet',
        icon: <Clock size={12} />,
        permissions: ['hr', 'masteradmin'],
        children: [
          {
            id: 'list',
            title: 'List',
            icon: <List size={12} />,
            navLink: '/apps/hrm/time-sheet/list',
            permissions: ['hr', 'masteradmin']
          },
          {
            id: 'date-range-list',
            title: 'Report',
            icon: <FileText size={12} />,
            navLink: '/apps/hrm/time-sheet/report/date-range',
            permissions: ['hr', 'masteradmin']
          },
          {
            id: 'timesheet-print-list',
            title: 'Print',
            icon: <Printer size={12} />,
            navLink: '/apps/hrm/time-sheet/print',
            permissions: ['hr', 'masteradmin']
          }
        ]
      },
      {
        id: 'piece-work',
        title: 'Piece Work',
        icon: <Archive size={12} />,
        navLink: '/apps/hrm/piece-work/list',
        permissions: ['hr', 'masteradmin']
      },
      {
        id: 'leaveboard',
        title: 'Leave Board',
        icon: <Calendar size={12} />,
        navLink: '/apps/hrm/employees/leave-board',
        permissions: ['hr', 'masteradmin']
      },
      {
        id: 'holidaycalendar',
        title: 'Holiday Calendar',
        icon: <Calendar size={12} />,
        navLink: '/apps/hrm/holiday-calendar',
        permissions: ['hr', 'masteradmin']
      }
    ]
  },
  {
    id: 'crm',
    title: 'Company Management',
    icon: <Layout size={20} />,
    permissions: ['admin', 'masteradmin', 'logistics'],
    children: [
      {
        id: 'list',
        title: 'Companies',
        icon: <Briefcase size={12} />,
        navLink: '/apps/company/list',
        permissions: ['admin', 'masteradmin', 'logistics']
      },
      // {
      //   id: 'view',
      //   title: 'View',
      //   icon: <Circle size={12} />,
      //   navLink: '/apps/crm/leads/view'
      // },
      // {
      //   id: 'usercustomer',
      //   title: 'Contacts',
      //   icon: <Users size={12} />,
      //   navLink: '/apps/crm/contacts/list',
      //   permissions: ['admin', 'masteradmin', 'logistics']
      // },
      {
        id: 'customer',
        title: 'Customer Portal',
        icon: <UserPlus size={12} />,
        navLink: '/apps/crm/customer-portal/list',
        permissions: ['admin', 'masteradmin', 'logistics']
      }
      // {
      //   id: 'leadslist',
      //   title: 'Leads',
      //   icon: <Slack size={12} />,
      //   navLink: '/apps/crm/leads/list',
      //   permissions: ['admin', 'masteradmin']
      // },
      // {
      //   id: 'dealslist',
      //   title: 'Deals',
      //   icon: <Layers size={12} />,
      //   navLink: '/apps/crm/deals/list',
      //   permissions: ['admin', 'masteradmin']
      // }
      // ,
      // {
      //   id: 'dealsProgress',
      //   title: 'Deals Kanban',
      //   icon: <Trello size={12} />,
      //   navLink: '/apps/crm/deals/kanban'
      // }
    ]
  },
  {
    id: 'freightforward',
    title: 'Freight Forward',
    icon: <Package size={20} />,
    permissions: ['admin', 'logistics', 'masteradmin'], //'customer' removed
    navLink: '/apps/freight-forward-request/list'
  },
  {
    id: 'configuration',
    title: 'Configuration',
    icon: <Settings size={20} />,
    permissions: ['developer', 'masteradmin'],
    children: [
      {
        id: 'hr',
        title: 'HR',
        icon: <Circle size={12} />,
        navLink: '/apps/configuration/hr',
        permissions: ['masteradmin', 'developer']
      },
      {
        id: 'company',
        title: 'Company Type',
        icon: <Circle size={12} />,
        navLink: '/apps/configuration/company',
        permissions: ['developer', 'masteradmin']
      },
      {
        id: 'qualityAssurance',
        title: 'Quality Assurance',
        icon: <Circle size={12} />,
        navLink: '/apps/configuration/quality-assurance',
        permissions: ['developer', 'masteradmin']
      },
      {
        id: 'commodity',
        title: 'Commodity',
        icon: <Circle size={12} />,
        navLink: '/apps/configuration/commodity',
        permissions: ['developer', 'masteradmin']
      },
      {
        id: 'packaging',
        title: 'Packaging',
        icon: <Circle size={12} />,
        navLink: '/apps/configuration/packaging',
        permissions: ['developer', 'masteradmin']
      },
      {
        id: 'transload-order',
        title: 'Transload Order',
        icon: <Circle size={12} />,
        navLink: '/apps/configuration/transload-order',
        permissions: ['developer', 'masteradmin']
      },
      {
        id: 'quality-issue',
        title: 'Quality Issue',
        icon: <Circle size={12} />,
        navLink: '/apps/configuration/quality-issue',
        permissions: ['developer', 'masteradmin']
      }
    ]
  },
  {
    id: 'terminal',
    title: 'Terminal',
    icon: <MapPin size={20} />,
    permissions: ['admin', 'masteradmin', 'logistics', 'terminal'],
    children: [
      {
        id: 'ingate-list',
        title: 'Ingate List',
        icon: <Circle size={12} />,
        navLink: '/apps/terminal/all/ingate',
        permissions: ['admin', 'masteradmin', 'logistics', 'terminal']
      },
      {
        id: 'outgate-list',
        title: 'Outgate List',
        icon: <Circle size={12} />,
        navLink: '/apps/terminal/all/outgate',
        permissions: ['admin', 'masteradmin', 'logistics', 'terminal']
      },
      {
        id: 'schedule-list',
        title: 'Schedule List',
        icon: <Circle size={12} />,
        navLink: '/apps/terminal/all/schedule',
        permissions: ['admin', 'masteradmin', 'logistics', 'terminal']
      },
      {
        id: 'edi-records',
        title: 'EDI Records',
        icon: <Circle size={12} />,
        navLink: '/apps/terminal/edi-records',
        permissions: ['admin', 'masteradmin', 'logistics']
      },
      {
        id: 'inventory-list',
        title: 'Equipment Inventory',
        icon: <Circle size={12} />,
        navLink: '/apps/equipment/inventory/list/all',
        permissions: ['admin', 'masteradmin', 'logistics', 'terminal']
      },
      {
        id: 'equipment-storage',
        title: 'Storage Equipment',
        icon: <Circle size={12} />,
        navLink: '/apps/equipment/storage/list/all',
        permissions: ['admin', 'masteradmin', 'logistics', 'terminal']
      },
      {
        id: 'equipment-miscellaneous',
        title: 'Misc Equipment',
        icon: <Circle size={12} />,
        navLink: '/apps/equipment/miscellaneous/list/all',
        permissions: ['admin', 'masteradmin', 'logistics', 'terminal']
      },
      {
        id: 'block-list',
        title: 'Block List',
        icon: <Circle size={12} />,
        navLink: '/apps/terminal/block-list',
        permissions: ['admin', 'masteradmin', 'logistics', 'terminal']
      }
    ]
  },
  {
    id: 'transportation',
    title: 'Transportation',
    icon: <Truck size={20} />,
    permissions: ['admin', 'masteradmin', 'dispatch', 'transportation', 'logistics'],
    children: [
      {
        id: 'inboundrequests',
        title: 'Inbound Requests',
        icon: <Circle size={12} />,
        navLink: '/apps/inbound-request/list',
        permissions: ['admin', 'masteradmin', 'dispatch', 'transportation', 'logistics']
      },
      {
        id: 'outboundrequests',
        title: 'Outbound Requests',
        icon: <Circle size={12} />,
        navLink: '/apps/outbound-request/list',
        permissions: ['admin', 'masteradmin', 'dispatch', 'transportation', 'logistics']
      },
      {
        id: 'yardmovement',
        title: 'Yard Movement',
        icon: <Circle size={12} />,
        navLink: '/apps/yard-movement/list',
        permissions: ['admin', 'masteradmin', 'dispatch', 'transportation', 'logistics']
      },
      {
        id: 'dispatchrequests',
        title: 'Dispatch',
        icon: <Circle size={12} />,
        navLink: '/apps/dispatch/list',
        permissions: ['admin', 'masteradmin', 'dispatch', 'transportation', 'logistics']
      },
      {
        id: 'toDoListTransportation',
        title: 'To Do List',
        icon: <Circle size={12} />,
        navLink: '/apps/to-do-list/list',
        permissions: ['admin', 'masteradmin', 'dispatch', 'transportation', 'logistics']
      },
      {
        id: 'driver',
        title: 'Truck Driver',
        icon: <Circle size={12} />,
        navLink: '/apps/transportation/driver/list',
        permissions: ['admin', 'masteradmin', 'dispatch', 'transportation', 'logistics']
      }
    ]
  },
  {
    id: 'transloadBooking',
    title: 'Transload Order',
    icon: <FastForward size={20} />,
    permissions: ['admin', 'logistics', 'masteradmin'],
    // navLink: '/apps/transload-order/list' //comment this if you want to show children
    children: [
      {
        id: 'transloadList',
        title: 'List',
        icon: <Circle size={12} />,
        navLink: '/apps/transload-order/list',
        permissions: ['admin', 'logistics', 'masteradmin']
      },
      {
        id: 'specialTransload',
        title: 'Special Transload',
        icon: <Circle size={12} />,
        navLink: '/apps/transload-order/special-transload/list',
        permissions: ['admin', 'logistics', 'masteradmin']
      },
      {
        id: 'temporaryStorage',
        title: 'Temporary Storage',
        icon: <Circle size={12} />,
        navLink: '/apps/transload-order/temporary-storage/list',
        permissions: ['admin', 'logistics', 'masteradmin']
      }
    ]
  },
  {
    id: 'inventory',
    title: 'Inventory',
    icon: <Layers size={20} />,
    permissions: ['admin', 'logistics', 'masteradmin'],
    children: [
      {
        id: 'cargoInventory',
        title: 'Cargo Inventory',
        icon: <Circle size={12} />,
        navLink: '/apps/inventory/cargo-inventory',
        permissions: ['admin', 'logistics', 'masteradmin']
      },
      {
        id: 'packagingList',
        title: 'Packaging',
        icon: <Circle size={12} />,
        navLink: '/apps/inventory/packaging-management',
        permissions: ['admin', 'logistics', 'masteradmin']
      },
      {
        id: 'dunnageList',
        title: 'Dunnage',
        icon: <Circle size={12} />,
        navLink: '/apps/inventory/dunnage-management',
        permissions: ['admin', 'logistics', 'masteradmin']
      }
    ]
  },
  {
    id: 'vesselVoyage',
    title: 'Vessel Voyage',
    icon: <Anchor size={20} />,
    permissions: ['admin', 'logistics', 'masteradmin'],
    children: [
      {
        id: 'vesselList',
        title: 'Vessels',
        icon: <Circle size={12} />,
        navLink: '/apps/vesselvoyage/vessel/list',
        permissions: ['admin', 'logistics', 'masteradmin']
      },
      // {
      //   id: 'terminalList',
      //   title: 'Terminals',
      //   icon: <Circle size={12} />,
      //   navLink: '/apps/vesselvoyage/terminal/list',
      //   permissions: ['admin', 'logistics', 'masteradmin']
      // },
      {
        id: 'vesselScheduleList',
        title: 'Schedule',
        icon: <Circle size={12} />,
        navLink: '/apps/vesselvoyage/schedule/list',
        permissions: ['admin', 'logistics', 'masteradmin']
      }
      // {
      //   id: 'vesselMindList',
      //   title: 'VesselMind',
      //   icon: <Circle size={12} />,
      //   navLink: '/apps/vesselvoyage/vesselmind/list',
      //   permissions: ['admin', 'logistics', 'masteradmin']
      // },
      // ,{
      //   id: 'vesselTracker',
      //   title: 'Tracker',
      //   icon: <Circle size={12} />,
      //   navLink: '/apps/vesselvoyage/tracker',
      //   permissions: ['admin', 'logistics', 'masteradmin']
      // }
    ]
  },
  {
    id: 'oceanBooking',
    title: 'Booking',
    icon: <BookOpen size={20} />,
    permissions: ['admin', 'logistics', 'masteradmin'],
    children: [
      {
        id: 'OceanBookingList',
        title: 'Ocean',
        icon: <Circle size={12} />,
        navLink: '/apps/ocean-booking/list',
        permissions: ['admin', 'logistics', 'masteradmin']
      },
      {
        id: 'LandBookingList',
        title: 'Land',
        icon: <Circle size={12} />,
        navLink: '/apps/land-booking/list',
        permissions: ['admin', 'logistics', 'masteradmin']
      }

    ]
  },
  {
    id: 'reports',
    title: 'Reports',
    icon: <Clipboard size={20} />,
    permissions: ['admin', 'logistics', 'masteradmin'],
    children: [
      {
        id: 'datasumm',
        title: 'Data Summary',
        icon: <Circle size={12} />,
        navLink: '/apps/reports/datasumm',
        permissions: ['admin', 'masteradmin']
      },
      {
        id: 'stuffing',
        title: 'Transloading',
        icon: <Circle size={12} />,
        navLink: '/apps/reports/stuffing',
        permissions: ['admin', 'logistics', 'masteradmin']
      },
      {
        id: 'special-reports',
        title: 'Special Reports',
        icon: <Circle size={12} />,
        navLink: '/apps/reports/special-reports',
        permissions: ['admin', 'logistics', 'masteradmin']
      },
      // certificate
      {
        id: 'certificate',
        title: 'Certificates',
        icon: <Circle size={12} />,
        navLink: '/apps/reports/certificates',
        permissions: ['admin', 'logistics', 'masteradmin']
      },
      // {
      //   id: 'shipments',
      //   title: 'Shipments',
      //   icon: <Circle size={12} />,
      //   navLink: '/apps/freight-forward-request/shipments',
      //   permissions: ['admin', 'logistics', 'masteradmin']
      // },
      {
        id: 'shipment-progress',
        title: 'Shipment Progress',
        icon: <Circle size={12} />,
        navLink: '/apps/reports/shipment-progress',
        permissions: ['admin', 'logistics', 'masteradmin']
      },
      {
        id: 'inbound-tracing',
        title: 'Inbound Tracing',
        icon: <Circle size={12} />,
        navLink: '/apps/reports/inbound-tracing',
        permissions: ['admin', 'logistics', 'masteradmin']
      },
      {
        id: 'cleaning-log',
        title: 'Cleaning Log',
        icon: <Circle size={12} />,
        navLink: '/apps/reports/cleaning-log',
        permissions: ['admin', 'logistics', 'masteradmin']
      }
    ]
  },

  /*{
    id: 'email',
    title: 'Email',
    icon: <Mail size={20} />,
    navLink: '/apps/email'
  },
  {
    id: 'chat',
    title: 'Chat',
    icon: <MessageSquare size={20} />,
    navLink: '/apps/chat'
  },
  {
    id: 'todo',
    title: 'Todo',
    icon: <CheckSquare size={20} />,
    navLink: '/apps/todo'
  },
  {
    id: 'calendar',
    title: 'Calendar',
    icon: <Calendar size={20} />,
    navLink: '/apps/calendar'
  },
  */
  {
    id: 'invoiceApp',
    title: 'Invoicing',
    icon: <FileText size={20} />,
    permissions: ['admin', 'masteradmin', 'logistics', 'accounting'],
    children: [
      {
        id: 'invoices_invoicing',
        title: 'Invoices',
        icon: <Circle size={12} />,
        navLink: '/apps/invoicing/t/inv/list',
        permissions: ['admin', 'masteradmin', 'logistics', 'accounting']
      },
      {
        id: 'bookings_charge_summary',
        title: 'Charge Summary',
        icon: <Circle size={12} />,
        navLink: '/apps/invoicing/t/chargesummary/list',
        permissions: ['admin', 'masteradmin', 'logistics', 'accounting']
      },
      // {
      //   id: 'transloading_invoices',
      //   title: 'Transloading Invoices',
      //   icon: <Circle size={12} />,
      //   navLink: '/apps/invoice/list',
      //   permissions: ['admin', 'masteradmin', 'logistics', 'accounting'],
      //   children: [
      //     {
      //       id: 'bookings_invoicing',
      //       // title: 'Charge Summary',
      //       title: 'Transloading Invoices',
      //       icon: <Circle size={12} />,
      //       navLink: '/apps/invoicing/t/booking/list',
      //       permissions: ['admin', 'masteradmin', 'logistics', 'accounting']
      //     },
      //   ]
      // },
      {
        id: 'rates',
        title: 'Rates',
        icon: <Circle size={12} />,
        navLink: '/apps/invoicing/rates',
        permissions: ['admin', 'masteradmin', 'accounting']
      },
      {
        id: 'service_agreement',
        title: 'Service Agreement',
        icon: <Circle size={12} />,
        navLink: '/apps/invoicing/service-agreement',
        permissions: ['admin', 'masteradmin', 'accounting']
      }
    ]
  },
  // {
  //   id: 'quoteApp',
  //   title: 'Quote',
  //   icon: <Edit3 size={20} />,
  //   permissions: ['admin', 'masteradmin'],
  //   children: [
  // {
  //   id: 'addQuote',
  //   title: 'Add Quote',
  //   icon: <Circle size={12} />,
  //   navLink: '/apps/quote/add',
  //   permissions: ['admin', 'masteradmin']
  // },
  // {
  //   id: 'quoteList',
  //   title: 'Quote List',
  //   icon: <Circle size={12} />,
  //   navLink: '/apps/quote/list',
  //   permissions: ['admin', 'masteradmin']
  // },

  //   ]
  // },
  {
    id: 'admin',
    title: 'Administation',
    icon: <Shield size={20} />,
    permissions: ['admin', 'masteradmin'],
    children: [
      {
        id: 'singlesignon',
        title: 'Single Sign On',
        icon: <Circle size={12} />,
        navLink: '/apps/admin/singlesignon',
        permissions: ['admin', 'masteradmin']
      },
      {
        id: 'roles',
        title: 'Roles',
        icon: <Circle size={12} />,
        navLink: '/apps/roles',
        permissions: ['admin', 'masteradmin']
      },
      {
        id: 'permissions',
        title: 'Permissions',
        icon: <Circle size={12} />,
        navLink: '/apps/permissions',
        permissions: ['admin', 'masteradmin']
      }
    ]
  }/*,
  {
    id: 'eCommerce',
    title: 'eCommerce',
    icon: <ShoppingCart size={20} />,
    children: [
      {
        id: 'shop',
        title: 'Shop',
        icon: <Circle size={12} />,
        navLink: '/apps/ecommerce/shop'
      },
      {
        id: 'detail',
        title: 'Details',
        icon: <Circle size={12} />,
        navLink: '/apps/ecommerce/product-detail'
      },
      {
        id: 'wishList',
        title: 'Wish List',
        icon: <Circle size={12} />,
        navLink: '/apps/ecommerce/wishlist'
      },
      {
        id: 'checkout',
        title: 'Checkout',
        icon: <Circle size={12} />,
        navLink: '/apps/ecommerce/checkout'
      }
    ]
  }*/
]
