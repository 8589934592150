function Email({ user, option, title, description, page }) {
  return `
    <!DOCTYPE html>
    <html>
      <head>
        <meta charset="UTF-8">
        <title>Help Request</title>
        <style>
          body {
            background-color: #ffffff;
            color: #24292e;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
            padding: 20px;
          }
          .container {
            max-width: 480px;
            margin: 0 auto;
            padding: 20px 0 48px;
            background-color: #ffffff;
            border-radius: 8px;
          }
          .title {
            font-size: 24px;
            line-height: 1.25;
            margin-top: 20px;
            color: #24292e;
          }
          .section {
            padding: 24px;
            border: 1px solid #dedede;
            border-radius: 5px;
            text-align: left;
            margin-top: 20px;
          }
          .text {
            margin: 0 0 10px 0;
            font-size: 16px;
            line-height: 1.5;
            color: #24292e;
          }
          .links {
            text-align: center;
            margin-top: 30px;
          }
          .link {
            color: #0366d6;
            font-size: 12px;
            text-decoration: none;
          }
          .link:hover {
            text-decoration: underline;
          }
          .footer {
            color: #6a737d;
            font-size: 12px;
            text-align: center;
            margin-top: 60px;
          }
          a {
            color: #0366d6;
            text-decoration: none;
          }
          a:hover {
            text-decoration: underline;
          }
        </style>
      </head>
      <body>
        <div class="container">
          <img src="https://www.wtcgroup.com/_next/image?url=%2Flogos%2FWTC2.png&w=256&q=75" alt="WTC Group Logo" width="55" height="32" style="display: block; margin-bottom: 20px;" />
          
          <p class="title">
            <strong>@${user}</strong>, has sent a help request!
          </p>
          
          <div class="section">
            <p class="text">
              How can we help you - <strong>${option}</strong>
            </p>
            <p class="text">
              Title - <strong>${title}</strong>
            </p>
            <p class="text">
              Description - <strong>${description}</strong>
            </p>
            <p class="text">
              Link to the page - <strong><a href="${page}">${page}</a></strong>
            </p>
          </div>
          
          <p class="links">
            <a href="https://www.shipsmp.com" class="link">Shipsmpl 2.0</a>
          </p>
          
          <p class="footer">
            WTC Group ・1275 Kingsway Ave ・Port Coquitlam, BC V3C1S2
          </p>
        </div>
      </body>
    </html>
  `
}

export default Email