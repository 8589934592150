// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { urlReportingService, urlCRMCompanyService } from '@src/endpoints'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'
import { ToastContent } from "@src/views/components/toast/ToastContent"

const ToastMessage = (heading, message, isSuccess) => {
  toast(t => (
    <ToastContent t={t} heading={heading} response={isSuccess} message={message} />
  ))
}

// get special customers
export const getSpecialCustomers = createAsyncThunk('specialCustomer/getSpecialCustomers', async data => {
  const { filter } = data
  const filterData = {
    customer: filter.customer || "CTC"
  }
  const response = await axios.post(`${urlReportingService}/GetAllSpecialReport`, filterData)
  return {
    customers: response.data.data,
    filterData
  }
})

//GET SPECIAL CUSTOMERS BY CUSTOMER CODE
export const getSpecialCustomersByCustomerCode = createAsyncThunk('specialCustomer/getSpecialCustomersByCustomerCode', async customerCode => {
  const response = await axios.get(`${urlReportingService}/GetSpecialReport/${customerCode}`)
  return {
    selectedCustomer: response.data.data,
    loadedCustomer: true
  }
})

//get company by code
export const GetCompanyByCode = createAsyncThunk('cleanliness/GetCompanyByCode', async (terminal) => {
  const response = await axios.get(`${urlCRMCompanyService}/GetCompanyByCode/${terminal}`)
  const selectedCompany = response.data
  return selectedCompany
})
//filter nova transloading reort by po number
export const getSpecialReportByPONumber = createAsyncThunk('specialCustomer/getSpecialReportByPONumber', async (PONumber) => {
  const response = await axios.get(`${urlReportingService}/GetNovaTransloadBookingReport/${PONumber}`)
  const selectedReportByPONumber = response.data
  return selectedReportByPONumber
})

//GET INTACT REPORT BY BILLING PERIOD
export const getIntactReportByBillingPeriod = createAsyncThunk('specialCustomer/getIntactReportByBillingPeriod', async (billingPeriod) => {
  const response = await axios.get(`${urlReportingService}/GetCTCIntactReport/${billingPeriod}`)
  const selectedReportbyBillingPeriod = response.data
  return selectedReportbyBillingPeriod
})

// get lift storage report by billing period
export const getLiftStorageReportByBillingPeriod = createAsyncThunk('specialCustomer/getLiftStorageReportByBillingPeriod', async (billingPeriod) => {
  const response = await axios.get(`${urlReportingService}/GetCTCLiftStorageReport/${billingPeriod}`)
  const selectedLiftStorageReportbyBillingPeriod = response.data
  return selectedLiftStorageReportbyBillingPeriod
})
//get ctc transload report
export const getCTCTransloadReport = createAsyncThunk('ReportingService/GetCTCTransloadReport', async (vesselName, voyageNumber) => {
  const response = await axios.get(`${urlReportingService}/GetCTCTransloadReport/${vesselName}/${voyageNumber}`)
  const selectedCTCtransloadReport = response.data
  return selectedCTCtransloadReport

})

export const specialCustomerSlice = createSlice({
  name: 'specialCustomer',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selected: null,
    filter: {},
    filterData: {},
    customers: [],
    selectedCustomer: null,
    selectedCompany: null,
    selectedReportByPONumber: null,
    CustomerCTC: null,
    selectedReportbyBillingPeriod: null,
    selectedCTCtransloadReport: null,
    selectedLiftStorageReportbyBillingPeriod: null

  },
  reducers: {
    selectPermission: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    }
  },
  extraReducers: builder => {

    builder.addCase(getSpecialCustomers.fulfilled, (state, action) => {
      state.customers = action.payload.customers
      state.filterData = action.payload.filterData
    })
    builder.addCase(getSpecialCustomersByCustomerCode.fulfilled, (state, action) => {
      state.selectedCustomer = action.payload.selectedCustomer
      state.loadedCustomer = action.payload.loadedCustomer
    })
    builder.addCase(GetCompanyByCode.fulfilled, (state, action) => {
      state.selectedCompany = action.payload
    })
    builder.addCase(getIntactReportByBillingPeriod.fulfilled, (state, action) => {
      state.selectedReportbyBillingPeriod = action.payload
    })
    builder.addCase(getSpecialReportByPONumber.fulfilled, (state, action) => {
      state.selectedReportByPONumber = action.payload
    })
    builder.addCase(getCTCTransloadReport.fulfilled, (state, action) => {
      state.selectedCTCtransloadReport = action.payload
    })
    builder.addCase(getLiftStorageReportByBillingPeriod.fulfilled, (state, action) => {
      state.selectedLiftStorageReportbyBillingPeriod = action.payload
    })
  }
})

export default specialCustomerSlice.reducer
