import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' // 👈️ Redux Imports
import axios from 'axios'// 👈️ Axios Imports
import { urlInvoiceService, urlInvoiceChargeSummary, urlReportingService, urlCRMCompanyService, urlServiceAgreement, urlConfigCacheService } from '@src/endpoints' // 👈️ API Microservice EndPoint
import { getUriParams } from '@src/utility/Utils' // 👈️ Utility

export const getData = createAsyncThunk('invoice/getData', async params => {
  const uri = getUriParams(params)
  const ReportFilter = {
    fromDate: params.fromDate === '' ? null : params.fromDate,
    toDate: params.toDate === '' ? null : params.toDate,
    // orderNumber: params.orderNumber === '' ? null : params.orderNumber,
    bookingNumber: params.bookingNumber === '' ? null : params.bookingNumber,
    customerId: params.customerId?.value || null,
    isBookingActive: params.isBookingActive === '' ? true : params.isBookingActive
    // ssLineId: params.ssLineId?.value
  }
  const response = await axios.post(`${urlInvoiceService}/GetInvoiceProvidedServiceList?${uri}`, ReportFilter)
  return {
    params,
    data: response.data.data,
    totalPages: response.data.data.length > 0 ? response.data.totalCount : 0,
    loaded: true
  }
})

//Invoice/GetAllTransloadingInvoices
export const getAllTransloadingInvoices = async params => {
  const uri = `page=${params.page}&perPage=${params.perPage}&q=${params.q}&sort=${params.sort}&sortColumn=${params.sortColumn}`
  const ReportFilter = {
    bookingNumber: params.bookingNumber === '' ? null : params.bookingNumber,
    invoiceNumber: params.invoiceNumber === '' ? null : params.invoiceNumber,
    invoiceStatus: params.status,
    customerId: params.customerId?.value || null
  }
  
  const res = await axios.post(`${urlInvoiceService}/GetAllInvoice?${uri}`, ReportFilter)
  if (res.status === 200) {
    return {data: res.data, success: true}
  } else {
    return {success: false, message: 'Internal server Error'}
  }
}

export const getAllTransloadingChargeSummary = async params => {
  const uri = `page=${params.page}&perPage=${params.perPage}&q=${params.q}&sort=${params.sort}&sortColumn=${params.sortColumn}`
  const ReportFilter = {
    bookingNumber: params.bookingNumber === '' ? null : params.bookingNumber,
    chargeSummaryNumber: params.invoiceNumber === '' ? null : params.invoiceNumber,
    invoiceGenerated: params.invoiceGenerated === '' ? null : params.invoiceGenerated,
    customerId: params.customerId?.value || null
  }
  
  const res = await axios.post(`${urlInvoiceChargeSummary}/GetAllChargeSummaries?${uri}`, ReportFilter)
  if (res.status === 200) {
    return {data: res.data, success: true}
  } else {
    return {success: false, message: 'Internal server Error'}
  }
}

export const getAllChargeSummaryList = createAsyncThunk('invoice/getAllChargeSummaryList', async (data) => {
  const { params, filter } = data

  const uri = getUriParams(params)

  const filterData = {
    status: filter.status || null,
    referenceNumber: filter.referenceNumber || null,
    chargeSummaryNumber: filter.chargeSummaryNumber || null,
    chargeSummaryType: filter.chargeSummaryType || null,
    customerCode: filter.customerCode || null
  }

  const response = await axios.post(`${urlInvoiceChargeSummary}/GetAllChargeSummaries?${uri}`, filterData)
  return {
    params,
    data: response.data.data,
    totalPages: response.data.totalCount,
    filterData
  }
})

export const getInvoice = async (id) => {
  const res = await axios.get(`${urlInvoiceService}/GetInvoice/${id}`)
  if (res.status === 200) {
    return {data: res.data, success: true}
  } else {
    return {success: false, message: 'Internal server Error'}
  }
}

///Invoice/GetInvoiceStatusCount
export const getInvoiceStatusCount = async () => {
  const res = await axios.get(`${urlInvoiceService}/GetInvoiceStatusCount`)
  if (res.data.success) {
    return {data: res.data.data, success: true}
  } else {
    return {success: false, message: 'Internal server Error'}
  }
}

//ChargeSummary/GetChargeSummaryStatusCount
export const getChargeSummaryStatusCount = async () => {
  const res = await axios.get(`${urlInvoiceChargeSummary}/GetChargeSummaryStatusCount`)
  if (res.data.success) {
    return {data: res.data.data, success: true}
  } else {
    return {success: false, message: 'Internal server Error'}
  }
}

export const getChargeSummary = async (id) => {
  const res = await axios.get(`${urlInvoiceChargeSummary}/GetChargeSummary/${id}`)
  if (res.status === 200) {
    return {data: res.data, success: true}
  } else {
    return {success: false, message: 'Internal server Error'}
  }
}

export const getStuffingReport = async (params) => {
  try {
    const res = await axios.get(`${urlReportingService}/GetTransloadBookingReport/${params.oid}/${params.bid}`)
  if (res.data.success) {
    return {data: res.data?.data, success: true}
  } else {
    return {success: false, message: 'Internal server Error'}
  }
  } catch (error) {
    return {success: false, message: 'Internal server Error'}
  }
}

// export const getDDReport = async (params) => {
//   const res = await axios.get(`${urlReportService}/GetDemurrageDetentionReport/${params}`)
//   if (res.data.success) {
//     return {data: res.data?.data, success: true}
//   } else {
//     return {success: false, message: 'Internal server Error'}
//   }
// }

export const getStorageReport = async (params) => {
  try {
    const res = await axios.get(`${urlReportingService}/GetStorageOutReport/${params.oid}/${params.bid}`)
    if (res.data.success) {
      return {data: res.data?.data, success: true}
    } else {
      return {success: false, message: 'Internal server Error'}
    }
  } catch (error) {
    return {success: false, message: 'Internal server Error'}
  }
}

export const updateInvoice = async (data) => {
  const res = await axios.post(`${urlInvoiceService}/UpdateTransloadingInvoice`, data)
  if (res.status === 200) {
    return {data: res.data, success: true}
  } else {
    return {success: false, message: 'Internal server Error'}
  }
}

export const deleteInvoice = async (id) => {
  const res = await axios.delete(`${urlInvoiceService}/DeleteInvoice/${id}`)
  if (res.data.success) {
    return {data: res.data, success: true}
  } else {
    return {success: false, errors: res.data.errors}
  }
}

//send invoice or mark as paid
export const sendOrPaidInvoice = async (data) => {
  try {
    const { isBeingPaid } = data
    const str = isBeingPaid ? 'PayInvoice' : 'SendInvoice'
  const res = await axios.put(`${urlInvoiceService}/${str}`, data)
  if (res.data.success) {
    return {data: res.data, success: true}
  } else {
    return {success: false, errors: res.data.errors}
  }
  } catch (error) {
    console.log(error)
    return {success: false, errors: ['Internal server Error']}
  }
}

export const addChargeDetail = async (data) => {
  try {
    const res = await axios.put(`${urlInvoiceChargeSummary}/AddChargeDetails`, data)
  if (res.data.success) {
    return {data: res.data.data, success: true}
  } else {
    return {success: false, message: res.data.errors[0]}
  }
  } catch (error) {
    return {success: false, message: 'Internal server Error'}
  }
}

export const updateChrgSumm = async (data) => {
  try {
    const res = await axios.put(`${urlInvoiceChargeSummary}/UpdateChargeDetails`, data)
  if (res.data.success) {
    return {data: res.data.data, success: true}
  } else {
    return {success: false, message: res.data.errors[0]}
  }
  } catch (error) {
    return {success: false, message: 'Internal server Error'}
  }
}

//delete charge detail
export const deleteChrgDetail = async (data) => {
  try {
    const res = await axios.put(`${urlInvoiceChargeSummary}/RemoveChargeDetails`, data)
    if (res.data.success) {
      return {data: res.data.data, success: true}
    } else {
      return {success: false, message: res.data.errors[0]}
    }
  } catch (error) {
    return {success: false, message: 'Internal server Error'}
  }
}

///ConfigCache/GetAllSelect/
export const getAllSelectOptions = async (type) => {
  const res = await axios.get(`${urlConfigCacheService}/GetAllSelect/${type}`)
  if (res.data.success) {
    return {data: res.data.data, success: true}
  } else {
    return {success: false, message: 'Internal server Error'}
  }
}

export const refreshChrgSumm = async (data) => {
  const res = await axios.post(`${urlInvoiceChargeSummary}/RefreshChargeSummary`, data)
  if (res.data.success) {
    return {data: res.data.data, success: true}
  } else {
    return {success: false, message: 'Internal server Error'}
  }
}

export const deleteChrgSumm = async (id) => {
  const res = await axios.delete(`${urlInvoiceChargeSummary}/DeleteChargeSummary/${id}`)
  if (res.status === 200) {
    return {data: res.data, success: true}
  } else {
    return {success: false, message: 'Internal server Error'}
  }
}

export const getCompanyById = createAsyncThunk('invoice/getById', async (id) => {
  const res = await axios.get(`${urlCRMCompanyService}/GetById/${id}`)
  return res.data
})

// export const deleteInvoice = createAsyncThunk('appInvoice/deleteInvoice', async (id, { dispatch, getState }) => {
//   await axios.delete('/apps/invoice/delete', { id })
//   await dispatch(getData(getState().invoice.params))
//   return id
// })

export const getAllServiceAgreementList = createAsyncThunk('invoice/getAllServiceAgreementList', async (data) => {
  const { params, filter } = data
  
  const uri = getUriParams(params)

  const filterData = {
    fromDate: filter.fromDate || null,
    toDate: filter.toDate || null,
    customerCode: filter.customerCode || null
  }

  const response = await axios.post(`${urlServiceAgreement}/GetAllServiceAgreements?${uri}`, filterData)
  return {
    params,
    data: response.data.data,
    totalPages: response.data.totalCount,
    filterData
  }
})

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState: {
    loaded: false,
    vloaded: false,
    verror: false,
    data: [],
    total: 1,
    params: {},
    transloadingInvoice: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.pending, (state) => {
        state.loaded = false
        state.vloaded = false
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loaded = action.payload.loaded
        state.data = action.payload.data
        state.total = action.payload.totalPages
        state.params = action.payload.params
      })
      //rejected
      .addCase(getData.rejected, (state) => {
        state.loaded = true
        state.verror = true
        state.vloaded = true
        state.data = []
      })
      .addCase(getAllChargeSummaryList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.filter = action.payload.filterData
      })
      .addCase(getAllServiceAgreementList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.filter = action.payload.filterData
      })
      //anything pending, loaded is false
      .addMatcher(
        action => {
          return action.type.endsWith('/pending')
        },
        (state) => {
          state.loaded = false
        }
      )
      //anything fulfilled, loaded is true
      .addMatcher(
        action => {
          return action.type.endsWith('/fulfilled')
        },
        (state) => {
          state.loaded = true
        }
      )
      // anything rejected, loaded is true
      .addMatcher(
        action => {
          return action.type.endsWith('/rejected')
        },
        (state) => {
          state.loaded = true
        }
      )
  }
})

export default invoiceSlice.reducer
